<template>
  <div class="max-width-700">
    <h1>Login</h1>
    <form @submit.prevent="login">
      <div class="form-group">
        <input
          type="email"
          class="form-control my-4"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Enter email"
        />
     
      </div>
      <div class="form-group">
        <input
          type="password"
          class="form-control my-4"
          id="exampleInputPassword1"
          placeholder="Password"
        />
      </div>

      <button type="submit" class="btn btn-black mt-4">Login</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "LoginComponent",
  components: {},
  methods: {
    login() {
      // Simplified login logic
      localStorage.setItem("userLoggedIn", true);
      this.$router.push({ name: "Admin" });
    },
  },
};
</script>
