import { createRouter, createWebHistory } from 'vue-router'
// import HomeComponent from '../Home.vue'
import InvoiceComponent from '../Invoice.vue'
import Login from '../Login.vue';
import PortfolioComponent from '../portfolio.vue';


const routes = [
  { path: '/', component: PortfolioComponent },
  { path: '/invoice', component: InvoiceComponent },
  {
    path: '/login',
    component: Login,
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
